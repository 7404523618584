import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authorizationGuard } from "./routes-guards";
import CrmHomeRoutes from "@/views/Appointments/routes";
import CallCenterRoutes from "@/views/CallCenter/call-center-routes";
import SMSRoutes from "@/views/SMS/routes";
import EmployeesRoutes from "@/views/Employees/routes";
import CompaniesRoutes from "@/views/Companies/companies-routes";
import crmMedicFormModule from "@/views/Patients/routes";
import crmConfigurationsModule from "@/views/Configurations/routes";
import PdfSignRoutes from "@/views/PdfSign/pdf-sign-routes";
import DashboardRoutes from "@/views/Dashboard/routes";
import NomenclatorRoutes from "@/views/Settings/routes";
import CrmNotificationsRoutes from "@/views/Reminder/routes";
import { apiBaseUrl } from "@/enviorment";
import QrComponent from "@/components/2fa/QrComponent.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/clients",
  },
  {
    path: "/auth",
    name: "auth",
    components: {
      Login: () => import("@/views/Login.vue"),
    },
    meta: {
      requiresLogged: false,
    },
  },
  {
    path: "/2fa",
    name: "2fa",
    component: () => import("@/components/2fa/QrComponent.vue"),
    
    meta: {
      requiresToken: true,
    },
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("@/views/Register.vue"),
  //   meta: {
  //     requiresLogged: false,
  //   },
  // },
  {
    path: "/reminder",
    component: () => import("@/views/Reminder/Main.vue"),
    meta: {
      meta: {
        requiresLogged: true,
      },
    },
    children: CrmNotificationsRoutes,
  },
  /*   {
    path: "/appointments",
    name: "CRM Home",
    component: () => import("@/views/Appointments/Main.vue"),
    meta: {
      meta: {
        requiresLogged: true,
      },
    },
    children: CrmHomeRoutes,
  }, */
  {
    path: "/call-center",
    component: () => import("@/views/CallCenter/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: CallCenterRoutes,
  },
  {
    path: "/chat",

    component: () => import("@/views/SMS/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: SMSRoutes,
  },
  {
    path: "/employees",

    component: () => import("@/views/Employees/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: EmployeesRoutes,
  },
  {
    path: "/companies",

    component: () => import("@/views/Companies/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: CompaniesRoutes,
  },
  {
    path: "/clients",

    component: () => import("@/views/Patients/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: crmMedicFormModule,
  },
  {
    path: "/config",

    component: () => import("@/views/Configurations/Main.vue"),
    meta: {
      requiresLogged: true,
    },
    children: crmConfigurationsModule,
  },
  // Pdf sign
  ...PdfSignRoutes,
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard/OtherDashboard.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "/nomenclators",

    component: () => import("@/views/Settings/Main.vue"),
    meta: {
      requiresAdmin: true,
    },
    children: NomenclatorRoutes,
  },
  {
    path: "/403",
    name: "Error 403",
    component: () => import(/* webpackChunkName: "403" */ "../views/403.vue"),
  },
  {
    path: "/register/:nameForm",
    name: "External_Lead",
    component: () =>
      import(
        /* webpackChunkName: "External Lead" */ "../views/FormExternalLead.vue"
      ),
    meta: {
      requiresLogged: false,
    },
  },
  //{
  //path: "/invoice",
  //name: "Invoice",
  //component: () =>
  //import(/* webpackChunkName: "Invoice" */ "../views/InvoiceForm.vue"),
  //meta: {
  //requiresLogged: false,
  //},
  //},
  {
    path: "/thank",
    name: "Thank",
    component: () =>
      import(/* webpackChunkName: "External Lead" */ "../views/Thank.vue"),
    meta: {
      requiresLogged: false,
    },
  },

  {
    path: "/swagger",
    beforeEnter(to, from, next) {
      window.location.href = `${apiBaseUrl}/api`;
    },
  },
  {
    path: "*",
    name: "Error 404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// ¡¡¡¡¡¡¡ enable this for login !!!!!!!!!
router.beforeEach(authorizationGuard);
// router.beforeEach(permissionsGuard);

export default router;
