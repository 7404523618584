import { Themes } from "@/models/Colors";
import { Link } from "@/models/Link";
import { Settings } from "@/models/Settings";
import { Contact } from "@/models/Sms";

// USER MUTATION
export function updateLocalStorage(
  state: any,
  { access, refresh }: { access: string; refresh: string }
): void {
  if (access != null || access != undefined) {
    state.accessToken = access;
    localStorage.setItem("access_token", access);
  }

  if (refresh != null || refresh != undefined) {
    state.refreshToken = refresh;
    localStorage.setItem("refresh_token", refresh);
  }
}

export function updateAccess(state: any, access: any): void {
  if (
    access != null ||
    access != undefined ||
    (access as string).trim() != ""
  ) {
    state.accessToken = access;
  }
}

export function destroyToken(state: any): any {
  state.accessToken = null;
  state.refreshToken = null;
  state.tokenTwoFa = "";
  state.needTwoFAuth = false;
  // state.profile = null;
  // state.student = null;
  localStorage.removeItem("impersonate");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("profile");
  localStorage.removeItem("filtersPreop");
  localStorage.removeItem("filtersPreoptypedate");
  localStorage.removeItem("filtersPreopdates");
  localStorage.removeItem("filtersPatients");
  localStorage.removeItem("filtersleads");
  localStorage.removeItem("needTwoFA");
  localStorage.removeItem("needTwoFA");
  localStorage.removeItem("columns");
  localStorage.removeItem("columnsL");
  // localStorage.removeItem("student");
}

export function updateProfile(state: any, profile: any): any {
  state.profile = profile;
  localStorage.setItem("profile", JSON.stringify(profile));
}
export function updateUserContacts(state: any, contacts: []) {
  state.userContacts = contacts;
}
export function mutTokenTwoFA(state: any, token: string) {
  state.tokenTwoFa = token;
}
export function mutNeedTwoFA(state: any, value: boolean) {
  state.needTwoFAuth = value;
  localStorage.setItem("needTwoFA", JSON.stringify(value));
}
export function mutDisplaylogo(state: any, value: boolean) {
  state.displaylogo = value;
}
export function loadingConfig(state: any, value: boolean) {
  state.loadingConfig = value;
}
export function updateloadingContact(state: any, value: boolean) {
  state.loadingContact = value;
}
export function updateAvatar(state: any, avatar: string): any {
  state.avatar = avatar;
}
export function updateStatus(state: any, status: string): any {
  state.status = status;
}
export function updateactivesms(state: any, activesms: boolean): any {
  state.activesms = activesms;
}
export function updateConnection(state: any, value: boolean): any {
  state.connected = value;
}

export function updateactivenotify(state: any, activenotify: boolean): any {
  state.activenotify = activenotify;
}
export function muttimeWorked(state: any, time: any): any {
  state.timeWorked = time;
}

export function updateSidebarSelected(state: any, item: string): any {
  localStorage.setItem("sidebar_selected", item);
  state.sidebarSelected = item;
}

export function mutDrawerExpand(state: any, drawerExpanded: boolean): void {
  state.drawerExpanded = drawerExpanded;
}
export function mutFilterDrawerExpand(state: any, filterDrawerExpanded: boolean): void {  
  state.filterDrawerExpanded = filterDrawerExpanded;
}
export function mutFilterDrawerExpandLead(state: any, filterDrawerExpandedLead: boolean): void {  
  state.filterDrawerExpandedLead = filterDrawerExpandedLead;
}
export function mutDataCRM(state: any, datacrm: any): void {
  state.dataCRM = datacrm;
}
export function mutNotifications(state: any, notifications: any): void {
  state.notifications = notifications;
}
export function mutAllNotifications(state: any, notifications: any): void {
  state.allnotifications = notifications;
}
export function mutconfigForm(state: any, configForm: any): void {
  state.configForm = configForm;
}
export function mutcountForm(state: any, value: any): void {
  state.configFormTotal = value;
}
export function mutcallTime(state: any, value: any): void {
  state.callTime = value;
}
export function mutmutedCall(state: any, value: boolean): void {
  state.mutedCall = value;
}
export function mutShowSms(state: any, value: boolean): void {
  state.showSmsGlobal = value;
}
export function mutDataCompany(state: any, com: any): void {
  state.company = com;
}
export function mutDataCRMTitle(state: any, title: string): void {
  state.dataCRM.title = title;
}
export function mutDataCRMMenu(state: any, menu: Link[]): void {
  state.dataCRM.menu = menu;
}
export function mutDataCRMColumnsLeads(state: any, clead: Link[]): void {
  state.dataCRM.columnsLeads = clead;
}
export function mutDataCRMColumnsClients(state: any, cClient: Link[]): void {
  state.dataCRM.columnsClients = cClient;
}
export function mutDataCRMNomPrice(state: any, value: boolean): void {
  state.dataCRM.nomenclatorsPrice = value;
}
export function mutDataCRMBGColor(state: any, bgColor: string): void {
  state.dataCRM.bgColor = bgColor;
}
export function mutDataCRMColors(state: any, themes: Themes): void {
  state.dataCRM.themes = themes;
}
export function mutDataCRMNomenclators(state: any, noms: any): void {
  state.dataCRM.nomenclators = noms;
}
export function mutMethodCall(state: any, value: any): void {
  state.dataCRM.methodCall = value;
}
export function mutRequireNotes(state: any, value: boolean): void {
  state.dataCRM.requireNotes = value;
}
export function mutSellerN(state: any, value: string): void {
  state.dataCRM.sellerN = value;
}
export function mutPatientN(state: any, value: string): void {
  state.dataCRM.patientN = value;
}
export function mutCoordinatorN(state: any, value: string): void {
  state.dataCRM.coordinatorN = value;
}
export function mutviewpayments(state: any, value: boolean) {
  state.dataCRM.viewPayments = value;
}
export function mutviewPPS(state: any, value: boolean) {
  state.dataCRM.viewPPS = value;
}
export function mutviewLabs(state: any, value: boolean) {
  state.dataCRM.viewLabs = value;
}
export function mutapproveQ(state: any, value: boolean) {
  state.dataCRM.approveQ = value;
}
export function mutsendQ(state: any, value: boolean) {
  state.dataCRM.sendQ = value;
}
export function mutUseMerchant(state: any, value: boolean) {
  state.dataCRM.useMerchant = value;
}
export function mutviewpatientStatus(state: any, value: boolean) {
  state.dataCRM.viewpatientStatus = value;
}
export function muttabPatientDetails(state: any, value: []) {
  state.dataCRM.tabPatientDetails = value;
}
export function mutviewMHistory(state: any, value: boolean) {
  state.dataCRM.viewMHistory = value;
}
export function mutleadHeaders(state: any, value: any) {
  state.dataCRM.leadHeaders = value;
}
export function mutSound(state: any, value: boolean) {
  state.dataCRM.soundNotification = value;
}
export function mutselecDocuments(state: any, value: boolean) {
  state.dataCRM.selecDocuments = value;
}
export function mutTypesAppoint(state: any, value: any): void {
  state.dataCRM.appointmetsTypes = value;
}
export function mutUseDocuSign(state: any, value: boolean): void {
  state.useDocunsing = value;
}
export function mutBaseURL(state: any, baseurl: string): void {
  state.VUE_APP_BASE_URL = baseurl;
}
export function mutSettings(state: any, settings: Settings[]): void {
  state.settings = settings;
}
export function mutConfigID(state: any, uuid: string): void {
  state.configId = uuid;
}
export function mutdefaulFormID(state: any, uuid: string): void {
  state.defaultFormId = uuid;
}
export function mutReqRange(state: any, reqrange: any): void {
  state.reqrange = reqrange;
}
export function mutTotalItems(state: any, val: number): void {
  state.totalitems = val;
}
export function mutTotalNotify(state: any, val: number): void {
  state.totalnotifications = val;
}
export function mutTotalAllNotify(state: any, val: number): void {
  state.totalallnotifications = val;
}

interface Notification {
  show: boolean;
  title?: string;
  body: string;
  type?: string;
}

export function mutNotification(state: any, notification: Notification): void {
  state.notification.type = notification.type || false;
  state.notification.title = notification.title || false;
  state.notification.body = notification.body || false;
  state.notification.show = notification.show;
}

export function mutShowNotification(state: any, value: boolean): void {
  state.notification.show = value;
}

export function mutcontactSelect(state: any, contactselect: Contact): void {
  state.contactSelect = contactselect;
}

/**
 * Set the Twilio Device status
 */
export function mutDeviceReady(state: any, status: boolean): void {
  state.twilioDevice.deviceReady = status;
}

export function mutShowDrawer(state: any, status: boolean): void {
  state.showDrawer = status;
}
export function setAccessToken(state: any, accessToken: string) {
  state.accessToken = accessToken;
}
export function setRefreshToken(state: any, refreshToken: string) {
  state.accessToken = refreshToken;
}
