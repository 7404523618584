import { RouteConfig } from "vue-router";

const NomenclatorRoutes: Array<RouteConfig> = [
  {
    path: "",
    redirect: {
      name: "Lead Status",
    },
  },
  /* {
    path: "procedures-subtypes",
    name: "Subtypes nomenclator",
    component: () =>
      import("@/views/Settings/Nomenclators/SubtypesNomenclator.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  /*  {
    path: "medical-locations",
    name: "Medical locations",
    component: () =>
      import("@/views/Settings/Nomenclators/MedicalLocations.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  {
    path: "ocupations",
    name: "Ocupations",
    component: () => import("@/views/Settings/Nomenclators/Ocupations.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  /* {
    path: "accessories",
    name: "Accessories",
    component: () => import("@/views/Settings/Nomenclators/Accessories.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  {
    path: "sources_nom",
    name: "Sources",
    component: () => import("@/views/Settings/Nomenclators/Sources.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "tags_nom",
    name: "Tags",
    component: () => import("@/views/Settings/Nomenclators/Tags.vue"),
    meta: {
      requiresAdmin: true,
    },
  },

  /*   {
    path: "appointment_types",
    name: "Appointment Types",
    component: () =>
      import("@/views/Settings/Nomenclators/AppointmentTypes.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  /*   {
    path: "procedure_status",
    name: "Procedure Status",
    component: () =>
      import("@/views/Settings/Nomenclators/ProcedureStatus.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  {
    path: "lead_status",
    name: "Lead Status",
    component: () => import("@/views/Settings/Nomenclators/LeadStatus.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "renewal_status",
    name: "Renewal Status",
    component: () => import("@/views/Settings/Nomenclators/RenewalStatus.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "npn",
    name: "Npn",
    component: () => import("@/views/Settings/Nomenclators/Npn.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "payto",
    name: "Pay To",
    component: () => import("@/views/Settings/Nomenclators/PayTo.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "paymentStatus",
    name: "Payment Status",
    component: () => import("@/views/Settings/Nomenclators/PaymentStatus.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  /*   {
    path: "client_status",
    name: "Client Status",
    component: () => import("@/views/Settings/Nomenclators/PatientStatus.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
  {
    path: "companies_nom",
    name: "Companies Nom",
    component: () => import("@/views/Settings/Nomenclators/Companies.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "add_stateCity",
    name: "State City",
    component: () => import("@/views/Settings/Nomenclators/StateCity.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "nomenclators/companies_nom/states/:id",
    name: "States Companies",
    component: () => import("@/views/Settings/components/StatesCompany.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "documents_nom",
    name: "Documents Nom",
    component: () => import("@/views/Settings/Nomenclators/Documents.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  /*   {
    path: "procedure_form",
    name: "Procedure Form",
    component: () =>
      import("@/views/Settings/Nomenclators/ProceduresForForm.vue"),
    meta: {
      requiresAdmin: true,
    },
  }, */
];

export default NomenclatorRoutes;
