/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import Vue from "vue";
import Vuex from "vuex";
import * as actions from "@/store/actions";
import * as mutations from "@/store/mutations";
import * as getters from "@/store/getters";

// Modules
import crmSMSModule from "@/views/SMS/store";
import crmCallCenterModule from "@/views/CallCenter/store";
import crmEmployeeModule from "@/views/Employees/store";
import crmCompaniesModule from "@/views/Companies/store";
import crmAccountModule from "./accountModule";
import crmMedicFormModule from "@/views/Patients/store";
import crmAppointmentsModule from "@/views/Appointments/store";
import crmDashboardModule from "@/views/Dashboard/store";
import crmSettingsModule from "@/views/Settings/store";
import crmConfigurationsModule from "@/views/Configurations/store";

import { Link } from "@/models/Link";
import { isEmpty } from "lodash";
Vue.use(Vuex);

const profile = JSON.parse(localStorage.getItem("profile") || "{}");
const status = profile != null && !isEmpty(profile) ? profile.agentStatus : "";
const accessToken = localStorage.getItem("access_token") || null;
const refreshToken = localStorage.getItem("refresh_token") || null;
const sidebarSelected = localStorage.getItem("sidebar_selected") || null;
const need2FA = localStorage.getItem("needTwoFA") || false;
const connect = localStorage.getItem("connect") || false;
export default new Vuex.Store({
  state: {
    accessToken: accessToken, // makes sure the user is logged in even after
    tokenTwoFa: "",
    connected: connect,
    needTwoFAuth: need2FA == "true" ? true : false,
    refreshToken: refreshToken,
    sidebarSelected: sidebarSelected,
    profile: profile,
    userContacts: [],
    loadingContact: false,
    avatar: "",
    status: status,
    displaylogo: false,
    timeWorked: {
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    contactSelect: {
      fullname: "",
      phone: "",
    },
    showDrawer: false,
    // For notifications
    notification: {
      show: false,
      title: "",
      body: "",
      type: "",
    },

    notifications: [],
    totalnotifications: 0,
    allnotifications: [],
    totalallnotifications: 0,
    activesms: false,
    activenotify: false,
    settings: [],
    drawerExpanded: false,
    filterDrawerExpanded:false,
    filterDrawerExpandedLead:false,
    twilioDevice: {
      deviceReady: false,
    },
    VUE_APP_BASE_URL: "",
    configId: "",
    defaultFormId: null,
    loadingConfig: false,
    useDocunsing: false,
    callTime: 0,
    mutedCall: false,
    showSmsGlobal: false,
    configForm: [],
    configFormTotal: null,
    company: "yogoms",
    reqrange: { limit: 100, offset: 0 },
    totalitems: 0,
    dataCRM: {
      columnsLeads:[],
      columnsClients:[],
      tabPatientDetails: [],
      soundNotification: false,
      useMerchant: false,
      viewPayments: false,
      viewPPS: false,
      viewLabs: false,
      viewMHistory: false,
      approveQ: false,
      sendQ: false,
      viewpatientStatus: false,
      leadHeaders: "both",
      selecDocuments: false,
      methodCall: "twilio",
      requireNotes: false,
      sellerN: "Seller",
      coordinatorN: "Surgical coordinator",
      patientN: "Client",
      bgColor: "linear-gradient(120deg, #3bbcf8 0%, #66aeec 100%)",
      title: "CRM Login",
      nomenclatorsPrice: false,
      nomenclators: {
        accessories: "Accessories",
      },
      themes: {
        light: {
          primary: "#00bcd5",
          hprimary: "#141414",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#ffc107",
        },
      },
      menu: new Array<Link>(),
    },
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    crmSMSModule: crmSMSModule,
    crmCallCenterModule: crmCallCenterModule,
    crmEmployeeModule: crmEmployeeModule,
    crmCompaniesModule: crmCompaniesModule,
    crmAccountModule: crmAccountModule,
    crmMedicFormModule: crmMedicFormModule,
    crmAppointmentsModule: crmAppointmentsModule,
    crmDashboardModule: crmDashboardModule,
    crmSettingsModule: crmSettingsModule,
    crmConfigurationsModule: crmConfigurationsModule,
  },
});

// EXPORT TYPES
export { AppGetters } from "./app-getters.types";
