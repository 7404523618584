<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-app>
    <v-main
      v-if="isLogin"
      v-cloak
      style="background-color: var(--crm-background)"
    >
      <router-view name="Login" v-if="!waiting"></router-view>
    </v-main>

    <template v-if="isLogin == false && isPdfSign == false">
      <template v-if="isExternal == false && isLogin == false && loggedIn">
        <v-navigation-drawer
          v-model="drawer"
          class="sidebar"
          :mini-variant="drawerExpanded"
          app
          v-cloak
          @update:mini-variant="mutDrawerExpand($event)"
          @input="onInput"
        >
          {{/* To collapse the drawer */}}
          <v-row justify="center" class="mt-0 mb-2">
            <template v-if="loadingImage && !noimage">
              <div class="noimage-wrapper">
                <v-progress-circular
                  indeterminate
                  color="blue"
                ></v-progress-circular>
              </div>
            </template>
            <template v-else-if="!loadingImage && !noimage && logo != null">
              <v-img max-height="50" width="200" :src="logo"></v-img>
            </template>
          </v-row>
          <v-list-item class="px-2 cursor-pointer" dark>
            <v-list-item-avatar color="blue">
              <v-img v-if="profile.avatar" :src="avatar"></v-img>
              <span v-else class="white--text headline">
                {{
                  profile.username != undefined
                    ? profile.username.slice(0, 2)
                    : ""
                }}
              </span>
            </v-list-item-avatar>

            <v-list-item-title class="text-truncate">
              <v-layout column>
                <span>{{ profile.fullname }}</span>
                <span class="text-caption">
                  {{ profile.phone }}
                </span>
              </v-layout>
            </v-list-item-title>

            <v-btn icon @click.stop="toggleDrawerExpand">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider />
          {{/* Navigation */}}
          <the-aside />
        </v-navigation-drawer>
      </template>

      <v-main style="background-color: var(--crm-background)" v-cloak>
        <main-toolbar :appBarProps="{ value: topBar }" />
        <router-view v-if="!waiting"></router-view>
      </v-main>
      <make-call />
      <sms-global />

      <!--    <dialog-incomming-call
        v-model="callCenterProvider.incomingCall"
        @hangup="callCenterProvider.incomingCall = false"
      /> -->
    </template>

    <v-main
      v-if="isPdfSign"
      class=""
      style="background-color: var(--crm-background)"
      v-cloak
    >
      <router-view name="AppSteps" class="fill-height"></router-view>
    </v-main>

    <notification />
    <v-snackbar
      top
      right
      timeout="5000"
      max-height="100"
      vertical
      text
      :color="type == 'sms' ? '#18C08E' : '#249CCC'"
      v-model="notify"
      ><div class="notifytitle">
        <h3>{{ type == "sms" ? "New Message" : "Reminder Notify" }}</h3>
        <v-btn color="pink" icon @click="notify = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <template v-if="type == 'sms'">
        <div>{{ from }}</div>
      </template>
      <div>{{ getSms(content) }}</div>
    </v-snackbar>
    <call-progress :initCall="progressCall" :recharge="recharged" />
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import TheAside from "./components/layout/TheAside.vue";
import { MainToolbar } from "@/components/layout";
import store from "@/store/index";
import Notification from "./components/Notification/Notification.vue";
import DialogIncommingCall from "./views/CallCenter/DialogIncommingCall.vue";
import { ACT_GENERAL_CONFIG } from "@/store/actions/types";
import { MUT_DRAWER_EXPAND } from "./store/mutations/types";
import { notifyError } from "./components/Notification";
import MakeCall from "./views/CallCenter/components/MakeCall.vue";
import SmsGlobal from "@/views/SMS/components/SmsGlobal.vue";
import axios from "axios";
import { apiBaseUrl } from "./enviorment";
import LogRocket from "logrocket";
import { io } from "socket.io-client";

import CallProgress from "./components/calls/CallProgress.vue";
import { getAPI } from "./api/axios-base";
// const AsidePatientDetails = () =>
//   import(
//     /* webpackChunkName: "aside-patient-details" */ "@/components/layout/AsidePatientDetails.vue"
//   );
// import { notifyError } from "./components/Notification";
// import i18n from "./i18n";

export default Vue.extend({
  name: "App",
  // inject: ["callCenterProvider"],
  components: {
    TheAside,
    MainToolbar,
    Notification,
    DialogIncommingCall,
    MakeCall,
    SmsGlobal,
    CallProgress,
    // AsidePatientDetails,
  },

  async mounted() {
    try {
      /*  this.progressCall = {
        begin_time: "2023-10-18T17:02:18Z",
        timeMarcEnd: "00:00:31:792",
        timeMarcInit: "00:00:12:605",
      }; */
      /* this.progressCall = {
        begin_time: "2023-10-18T20:02:18Z",
        timeMarcEnd: null,
        timeMarcInit: "00:00:12:605",
      };
      this.recharged = true;
      this.mutCallProgress(true);*/

      if (this.loggedIn) {
        this.getLogo();
        this.getUnread();
        this.updateactivenotify(true);
      } else {
        this.mutNeedTwoFA(false);
        this.mutTokenTwoFA("");
        this.updateactivenotify(false);
      }
    } catch (error) {
      notifyError("An error occurred while the device is loading");
    }

    if (!this.$route.path.includes("register")) {
      await this.act_config();
      this.$vuetify.theme.themes.light = this.dataCRM.themes.light;
      window.sheduleMessageRequest = 0;
      this.waiting = false;
    }
  },
  data: () => ({
    waiting: true,
    drawer: false,
    topBar: false,
    interval: null,
    intervalnotify: null,
    noimage: false,
    loadingImage: false,
    logo: null,
    connection: null,
    notify: false,
    content: "",
    from: "",
    type: "sms",
    progressCall: null,
    recharged: false,
  }),

  computed: {
    ...mapState([
      "drawerExpanded",
      "profile",
      "showDrawer",
      "dataCRM",
      "avatar",
      "activesms",
      "activenotify",
      "accessToken",
    ]),
    ...mapGetters(["loggedIn", "isWorker", "isAgent", "isAdmin", "isSuper"]),
    isLogin() {
      return this.$route.name == "auth";
    },
    isPdfSign() {
      return this.$route.path.includes("clients/check");
    },
    isExternal() {
      return this.$route.path.includes("register")||this.$route.path.includes('2fa');
    },
  },

  methods: {
    ...mapMutations([
      MUT_DRAWER_EXPAND,
      "mutShowDrawer",
      "updateactivesms",
      "updateactivenotify",
      "mutTokenTwoFA",
      "mutNeedTwoFA",
      "updateConnection",
    ]),
    ...mapMutations("crmCallCenterModule", ["mutCallProgress"]),
    ...mapActions({ act_config: ACT_GENERAL_CONFIG }),
    ...mapActions(["actGetNotifications"]),

    ...mapActions("crmSMSModule", ["getUnread"]),
    ...mapMutations("crmSMSModule", ["mutContainUnread"]),
    initLogRocket() {
      LogRocket.init("8ru5fv/yogo");
      LogRocket.identify(this.profile.uuid, {
        name: this.profile.fullname,
        phone: this.profile.phone,
        email: this.profile.email,
      });
    },
    connectToWS() {
      this.connection = io(apiBaseUrl, {
        transports: ["websocket"],
      });
      this.connection.on("connect", () => {
        localStorage.setItem("connect", "true");
        this.updateConnection(true);
        if (this.accessToken) {
          this.connection.emit("authentication", {
            event: "authentication",
            token: this.accessToken,
          });
        }
      });
    },
    checkCall() {
      getAPI("/zoom/get-last-call-status")
        .then((res) => {
          const response = res.data;
          if (response.begin_time) {
            this.progressCall = response;
            this.recharged = true;
            this.mutCallProgress(true);
          }
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    getSms(sms) {
      if (sms) {
        if (sms.length > 20) {
          return sms.substr(0, 20) + "...";
        }
        return sms;
      }
      return "";
    },
    getName(response) {
      if (response) {
        if (response.lead) {
          return `${response.lead.name} ${response.lead.last_name}`;
        }
        if (response.patient) {
          return response.patient.fullname;
        }
        return response.from;
      }
      return "";
    },

    toggleDrawerExpand() {
      this.mutDrawerExpand(!this.drawerExpanded);
    },
    onInput(state) {
      this.mutShowDrawer(state);
    },

    /*  getUnreadSms() {
      this.getUnread();
    }, */
    /* initSms() {
      if (this.activesms == true) {
        this.interval = setInterval(this.getUnreadSms, 30000);
      }
    }, */

    getLogo() {
      this.loadingImage = true;
      this.noimage = false;

      const url = `${apiBaseUrl}/minio/getLogo`;
      axios({
        method: "get",
        url: url,
        responseType: "blob",
      })
        .then((res) => {
          const buffer = new Blob([res.data]);
          const file_path = URL.createObjectURL(buffer);
          this.logo = file_path;
          this.noimage = false;
          this.loadingImage = false;
        })
        .catch((error) => {
          this.noimage = true;
          this.loadingImage = false;
        });
    },
  },
  watch: {
    // To sync the expanded state
    drawerExpanded(val) {
      this.mutDrawerExpand(val);
    },
    showDrawer(val) {
      this.drawer = val;
    },

    activenotify(value) {
      if (value == false) {
        this.connection.disconnect();
        localStorage.removeItem("connect");
        this.mutCallProgress(false);
        this.progressCall = null;
        this.recharged = false;
      } else {
        //  this.initLogRocket();
        this.checkCall();
        this.getUnread();
        this.actGetNotifications();
        this.connectToWS();

        this.connection.on("sms_received", (response, e) => {
          this.updateactivesms(true);
          this.from = `From: ${this.getName(response)}, `;
          this.content = response.message;
          this.notify = true;
          this.type = "sms";
          this.getUnread();
        });

        this.connection.on("disconnect", (res) => {
          localStorage.setItem("connect", "false");
          this.updateConnection(false);
        });

        this.connection.on("notify_received", (response, e) => {
          this.content = response.content;
          this.notify = true;
          this.type = "notify";
          this.actGetNotifications();
        });
        this.connection.on("call_recording_stared", (response, e) => {
          this.progressCall = response;
          this.recharged = false;
          this.mutCallProgress(true);
        });
        this.connection.on("call_recording_ended", (response, e) => {
          this.mutCallProgress(false);
          this.progressCall = null;
          this.recharged = false;
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.noimage-wrapper {
  display: flex;
  max-height: 50px;
}
.notifytitle {
  display: flex;
  width: 300px;
  justify-content: space-between;
}
</style>
